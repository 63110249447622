import React from "react";

function SlotsIcons({ slot, onClick, selectedSlot, disable }) {
  // if (selectedSlot === "helmet") {
  //   if (slot.title === "hood-hat-hair" || slot.title === "mask") {
  //     console.log(slot);
  //     slot.img = slot.disable;
  //   } else {
  //     slot.img = slot.img;
  //   }
  // }

  const clickHandle = (title) => {
    onClick(title);
  };

  return (
    <>
      <div onClick={() => clickHandle(slot.title)}>
        <ul
          className={`icn-list ${selectedSlot === slot.title ? "active" : ""} `}
        >
          <li>
            {selectedSlot === slot.title ? (
              <>
                <img src={slot.selectedImage} alt={slot.title} />
              </>
            ) : (
              <img src={slot.img} alt={slot.title} />
            )}
          </li>
        </ul>
      </div>
    </>
  );
}

export default SlotsIcons;
