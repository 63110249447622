import "./App.css";
import Minter from "./Minter";

function App({ image }) {
  return (
    <div className="App">
      <Minter></Minter>
    </div>
  );
}

export default App;
