import { useEffect, useState } from "react";
import swal from "sweetalert";

// import Box from "./assets/images/box.png";
//import Box from "./assets/images/icons/Dice1.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import Web3 from "web3";

import EthereumSession from "./eth-session";
import { config } from "./config";
import { slotsNew } from "./data/data";

// import LightBulb from "./assets/images/icons/lightbulb.svg";
import SlotsIcons from "./components/Slots";
import Av_logo from "./assets/images/av_logo.png";
import Arrow from "./assets/images/arrow.png";
import Ban from "./assets/images/new-icons/trash.gif";
import LightBulb from "./assets/images/new-icons/light.gif";

import "react-lazy-load-image-component/src/effects/blur.css";

//const baseURI = "http://local.avatar.com";
const baseURI = "https://avatest.herodevelopment.com";

let connectOnce = true;

//metaciples powerpass
const chain = EthereumSession.COMMON_CHAINS[4];
const ppSession = new EthereumSession({
  chain: chain,
  contractAddress: "0x0558644F90232F4C74937114A7D63a0291cdbF8e",
  contractABI: config.POWER_PASS_ABI,
});

const settings = {
  dots: false,
  speed: 500,
  slidesToShow: 10,
  slidesToScroll: 4,
  arrows: true,
  infinite: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        dots: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 4,
        arrows: true,
        infinite: false,
      },
    },
  ],
};

const Minter = (props) => {
  const [account, setAccount] = useState(null);
  const [collectionFilter, setCollectionFilter] = useState("");
  const [currentSlot, setCurrentSlot] = useState("");

  const [avatar, setAvatar] = useState({});

  const [bodyStyleAssets, setBodyStyleAssets] = useState([]);
  const [expressionAssets, setExpressionAssets] = useState([]);
  const [colorAssets, setColorAssets] = useState([]);
  // setEyesForEliteApe
  const [eyesForEliteApe, setEyesForEliteApe] = useState([]);

  const [lightVariations, setLightVariations] = useState([]);

  //TODO: 1 variable
  const [lightSetup, setLightSetup] = useState(2);
  const [bodyStyle, setBodyStyle] = useState("male");

  //show-hide
  const [hasLightingModal, showLightingModal] = useState(false);

  //const [showColorPopup, setShowColorPopup] = useState(false);

  //caches
  const [collections, setCollections] = useState([]);

  //////////////////////////////////////////////////////////////

  const addOrReplace = (oldAvatar, asset) => {
    if (asset.slot === "helmet") {
      delete oldAvatar["head-eyes"];
    }
    return {
      ...oldAvatar,
      [asset.slot]: asset,
    };
  };

  /**
   * APIs
   **/
  const fetchAssets = async (args, compat) => {
    const init = {};
    const session = getSession();
    if (session) {
      init.headers = {
        Authorization: session.token,
      };
    }

    if (collectionFilter && !args.collection) {
      args.collection = collectionFilter;
    }

    if (compat && avatar.body && avatar.body.collection === "elite-apes") {
      args.compat_apes = 1;
    }

    const searchParams = new URLSearchParams(args);
    const queryString = Object.keys(args).length ? searchParams.toString() : "";
    const url = `${baseURI}/api/1.0/assets?${queryString}`;
    console.debug({ url });
    const response = await fetch(url, init);
    if (response.ok) {
      const res = await response.json();
      return res;
    } else {
      const text = await response.text();
      console.warn({ text });
      return {
        data: [],
      };
    }
  };

  const fetchCollections = async () => {
    const response = await fetch(`${baseURI}/api/1.0/collection`);
    if (response.ok) {
      const tmp = await response.json();
      tmp.unshift({ name: "Any/All", slug: "" });
      return tmp;
    } else {
      const text = await response.text();
      console.warn({ text });
      return [];
    }
  };

  const fetchLightVariations = async (args) => {
    const searchParams = new URLSearchParams(args);
    const queryString = Object.keys(args).length ? searchParams.toString() : "";
    const url = `${baseURI}/api/1.0/variations/light?${queryString}`;

    const response = await fetch(url);
    if (response.ok) {
      const res = await response.json();
      return res;
    } else {
      const text = await response.text();
      console.warn({ text });
      return {
        data: [],
      };
    }
  };

  /**
   * functions
   **/
  const init = async () => {
    const collections = await fetchCollections();
    const backgroundRes = await fetchAssets({ slot: "background" });
    const bodyRes = await fetchAssets({
      collection: "metaciples",
      slot: "body",
      slug: "1",
      light_setup: lightSetup,
    });

    const updatedAvatar = {
      background: backgroundRes.data[29],
      body: bodyRes.data[0],
    };
    setAvatar(updatedAvatar);
    setCollections(collections);
    handleConnect(false);
    handleSlotSelected("body");
  };

  const createAuth = async () => {
    const account = Web3.utils.toChecksumAddress(ppSession.wallet.accounts[0]);
    const message = {
      account: account,
      timestamp: Math.round(Date.now() / 1000),
    };
    const hash = Web3.utils.soliditySha3({
      type: "string",
      value: JSON.stringify(message),
    });

    const signature = await ppSession.web3client.eth.personal.sign(
      hash,
      account
    );
    return {
      message,
      signature,
    };
  };

  const getAuth = () => {
    try {
      const item = sessionStorage.getItem("auth");
      return JSON.parse(item);
    } catch (err) {
      return null;
    }
  };

  const setAuth = (auth) => {
    try {
      sessionStorage.setItem("auth", JSON.stringify(auth));
      return true;
    } catch (err) {
      return false;
    }
  };

  const isValidAuth = () => {
    const auth = getAuth();
    if (auth && auth.message && auth.signature) {
      const created = auth.message.timestamp;
      const expires = auth.message.timestamp + 60 * 60; //60 minutes
      const time = Math.round(Date.now() / 1000);
      if (created < time && time < expires) return true;
    }

    return false;
  };

  const createSession = async () => {
    const auth = getAuth();
    const response = await fetch(`${baseURI}/api/1.0/session`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        message: JSON.stringify(auth.message),
        signature: auth.signature,
      }),
    });

    if (response.ok) {
      const session = await response.json();
      setSession(session);
      return true;
    } else {
      console.warn("login failed");
      return false;
    }
  };

  const getSession = () => {
    try {
      const item = sessionStorage.getItem("session");
      return JSON.parse(item);
    } catch (err) {
      return null;
    }
  };

  const setSession = (session) => {
    try {
      sessionStorage.setItem("session", JSON.stringify(session));
      return true;
    } catch (err) {
      return false;
    }
  };

  const isValidSession = () => {
    const session = getSession();
    if (session && session.created && session.expires) {
      const time = Math.round(Date.now() / 1000);
      if (session.created < time && time < session.expires)
        return !!session.token;
    }

    return false;
  };

  /**
   * handlers
   **/
  const handleAccountsChanged = (accounts) => {
    ppSession.wallet.accounts = accounts;
    handleConnect(true);
  };

  const handleConnect = async (deep) => {
    if (await ppSession.connectWeb3(deep)) {
      if (connectOnce && window.ethereum && window.ethereum.on) {
        connectOnce = false;
        window.ethereum.on("accountsChanged", handleAccountsChanged);
      }

      const account = Web3.utils.toChecksumAddress(
        ppSession.wallet.accounts[0]
      );
      setAccount(account);

      if (!isValidAuth()) {
        const auth = await createAuth();
        setAuth(auth);
        setSession(null);
      }

      if (!isValidSession()) {
        await createSession();
      }
    }
  };

  //1
  const handleBodyStyleSelected = async (asset) => {
    const res = await fetchAssets({
      slot: asset.slot,
      style: asset.style,
      light_setup: lightSetup,
      skin_color: 0,
      pageSize: 1000,
    });

    setAvatar(addOrReplace(avatar, asset));
    setBodyStyle(asset.style);
    setBodyStyleAssets([]); //unset
    setColorAssets([]); //unset
    setCurrentSlot("body");
    setExpressionAssets(res.data);
    showLightingModal(false);
  };

  //2
  const handleExpressionSelected = async (asset) => {
    if (currentSlot === "background") {
      setAvatar(addOrReplace(avatar, asset));
      return;
    }

    const res = await fetchAssets({
      collection: asset.collection,
      slot: asset.slot,
      slug: asset.slug,
      style: asset.style,
      light_setup: lightSetup,
      pageSize: 1000,
    });

    console.info(
      `handleExpressionSelected > ${res.data.length} color assets found`
    );
    setAvatar(addOrReplace(avatar, asset));
    //setBodyStyle( asset.style );
    setBodyStyleAssets([]); //unset
    setColorAssets(res.data);
    //setCurrentSlot( 'body' );
    setExpressionAssets([]); //unset
    showLightingModal(false);
  };

  //3
  const handleColorSelected = async (asset) => {
    if (asset.collection === "elite-apes" && asset.slot === "helmet") {
      setAvatar(addOrReplace(avatar, asset));
      const res = await fetchAssets({
        collection: "elite-apes",
        slot: "head-eyes",
        style: asset.style,
        asset_color: asset.asset_color,
        light_setup: lightSetup,
        pageSize: 1000,
      });
      if (res.data.length > 0) {
        setEyesForEliteApe(res.data);
        setColorAssets([]);
      }
    } else {
      setAvatar(addOrReplace(avatar, asset));
      showLightingModal(false);
    }
  };

  const handleCollectionFilter = (e) => {
    setCollectionFilter(e.target.value);
    //setShowColorPopup(false);
  };

  const handleMint = async () => {
    console.info({ avatar });
    if (!avatar.length) {
      alert("Please select additional traits");
      return;
    }

    await handleConnect(true);
    if (!isValidSession()) {
      alert("Please connect");
      return;
    }

    const avatarObject = {};
    Object.values(avatar).forEach((asset) => {
      avatarObject[asset.slot] = asset.uuid;
    });

    const init = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: getSession().token,
      }),
      body: JSON.stringify({
        recipe: avatarObject,
        status: "RESERVED",
      }),
    };

    const response = await fetch(`${baseURI}/api/1.0/recipe/mint`, init);
    if (!response.ok) {
      const text = await response.text();
      try {
        const parsed = JSON.parse(text);
        if (parsed && parsed.message) alert(parsed.message);
        else alert(text);
      } catch (err) {
        alert(text);
      }
      return;
    }

    const data = await response.json();
    if (data.hasErrors) {
      alert("errors");
      return;
    }

    try {
      const mintSession = new EthereumSession({
        chain: chain,
        contractAddress: data.collection,
        contractABI: config.COLLECTION_ABI,
      });
      await mintSession.connectWeb3(true);

      const recipeId = Web3.utils.padLeft(
        Web3.utils.numberToHex(data.recipeId),
        64
      );
      await mintSession.contract.methods
        .mint(recipeId, data.recipeData, data.signature)
        .estimateGas({ from: account });
      await mintSession.contract.methods
        .mint(recipeId, data.recipeData, data.signature)
        .send({ from: account });
    } catch (err) {
      const ethErr = EthereumSession.getError(err);
      if (ethErr.code === 4001) {
        alert("Please try again and confirm the transaction");
      } else if (ethErr.message) {
        alert(ethErr.message);
      } else {
        alert(String(ethErr));
      }
    }
  };

  const handleSlotSelected = async (newSlot, force) => {
    if( !force ){
      if (
        avatar["helmet"] &&
        avatar["helmet"].collection === "elite-apes" &&
        avatar["head-eyes"] === undefined
      ) {
        swal(
          "ooops",
          "Please select eyes before continuing with your Elite-Apes avatar creation.",
          "error"
        );
        return;
      }
    }

    if (currentSlot === newSlot && !force) return;

    if (newSlot === "background") {
      const res = await fetchAssets({ slot: "background" });
      setExpressionAssets(res.data);
    } else if (newSlot === "body") {
      const res = await fetchAssets({
        collection: "metaciples",
        slot: "body",
        slug: "1",
        light_setup: lightSetup,
        skin_color: 0,
        pageSize: 1000,
      });

      //setAvatar(addOrReplace(avatar, asset));
      //setBodyStyle( asset.style );
      setBodyStyleAssets(res.data);
      setExpressionAssets([]); //unset
    } else {
      const res = await fetchAssets(
        {
          slot: newSlot,
          style: bodyStyle,
          asset_color: 0,
          light_setup: lightSetup,
          pageSize: 1000,
        },
        true
      );
      setExpressionAssets(res.data);
    }

    setColorAssets([]); //unset
    //setShowColorPopup(false);
    setCurrentSlot(newSlot);
    setEyesForEliteApe([]); //unset
    showLightingModal(false);
  };

  const handleResetSlot = () => {
    if (currentSlot === "background") return;


    const updatedAvatar = { ...avatar };
    if (colorAssets.length) {
      const asset = colorAssets[0];
      updatedAvatar[asset.slot] = asset;
      if (asset.slot === "body") {
        handleBodyStyleSelected(asset);
      } else {
        handleSlotSelected(asset.slot, true);
      }
    } else if (expressionAssets.length) {
      const asset = expressionAssets[0];
      if (asset.slot === "body") {
        updatedAvatar[asset.slot] = asset;
        handleSlotSelected("body", true);
      }
      else{
        delete updatedAvatar[currentSlot];
      }
    } else if(eyesForEliteApe.length) {
      delete updatedAvatar["head-eyes"];
      if (updatedAvatar["head-eyes"]) {
        const asset = updatedAvatar["helmet"];
        handleColorSelected(asset);
      }
      else{
        const asset = updatedAvatar["helmet"];
        handleExpressionSelected(asset);
      }
    } else {
      delete updatedAvatar[currentSlot];
    }

    setAvatar(updatedAvatar);
    showLightingModal(false);
  };

  const handleToggleLightSetup = async () => {
    if (hasLightingModal) {
      showLightingModal(false);
    } else {
      const res = await fetchLightVariations({
        "slot[body]": avatar.body.slug,
        style: avatar.body.style,
        skin_color: avatar.body.skin_color,
      });

      setLightVariations(res.data.flat());
      showLightingModal(true);
    }
  };

  const handleSelectLightSetup = async (ls_asset) => {
    const newAssets = await Promise.all(
      Object.values(avatar).map(async (asset) => {
        //keep the background as-is
        if (asset.slot === "background") {
          return asset;
        } else if (asset.slot === "body") {
          console.info({
            light_setup: ls_asset.light_setup,
          });
          const res = await fetchAssets({
            slot: asset.slot,
            slug: asset.slug,
            style: asset.style,
            //asset_color: asset.asset_color,
            light_setup: ls_asset.light_setup,
            skin_color: asset.skin_color,
          });

          if (res.data.length) {
            return res.data[0];
          } else return null;
        } else {
          const res = await fetchAssets({
            slot: asset.slot,
            slug: asset.slug,
            style: asset.style,
            asset_color: asset.asset_color,
            light_setup: ls_asset.light_setup,
          });

          if (res.data.length) {
            return res.data[0];
          } else return null;
        }
      })
    );

    const updatedAvatar = {};
    newAssets.forEach((asset) => {
      if (asset) {
        updatedAvatar[asset.slot] = asset;
      }
    });

    setAvatar(updatedAvatar);
    setLightSetup(ls_asset.light_setup);
  };

  /**
   * useEffect
   **/

  //run-once to setup
  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (bodyStyleAssets.length) {
      handleSlotSelected("body", true);
    } else if (expressionAssets.length) {
      const asset = avatar[currentSlot];
      if (asset) {
        if (currentSlot === "body") handleBodyStyleSelected(asset);
        else handleSlotSelected(asset.slot, true);
      }
    } else if (colorAssets.length) {
      const asset = avatar[currentSlot];
      if (asset) {
        handleExpressionSelected(asset);
      }
    }

    // eslint-disable-next-line
  }, [lightSetup]);

  const renderBody = () => {
    if (bodyStyleAssets.length) {
      return bodyStyleAssets.map((asset) => {
        const isActive = avatar.body?.uuid === asset.uuid;
        return (
          <div key={asset.uuid} onClick={() => handleBodyStyleSelected(asset)}>
            <ul className={`img-display-list `}>
              <li
                className={`${
                  isActive && "img-dis-active"
                } slot-${currentSlot}`}
              >
                <LazyLoadImage
                  effect="blur"
                  src={asset.thumbnail}
                  alt={asset.display_name}
                ></LazyLoadImage>
              </li>
            </ul>
          </div>
        );
      });
    }

    if (expressionAssets.length) {
      return expressionAssets.map((asset) => {
        const isActive = avatar.body?.uuid === asset.uuid;
        return (
          <div key={asset.uuid} onClick={() => handleExpressionSelected(asset)}>
            <ul className={`img-display-list `}>
              <li
                className={`${
                  isActive && "img-dis-active"
                } slot-${currentSlot}`}
              >
                <LazyLoadImage
                  effect="blur"
                  src={asset.thumbnail}
                  alt={asset.display_name}
                ></LazyLoadImage>
              </li>
            </ul>
          </div>
        );
      });
    }
    if (colorAssets.length) {
      return colorAssets.map((asset) => {
        const isActive = avatar.body?.uuid === asset.uuid;
        return (
          <div key={asset.uuid} onClick={() => handleColorSelected(asset)}>
            <ul className={`img-display-list`}>
              <li
                className={`${
                  isActive && "img-dis-active"
                } slot-${currentSlot}`}
              >
                <LazyLoadImage
                  effect="blur"
                  src={asset.thumbnail}
                  alt={asset.display_name}
                ></LazyLoadImage>
              </li>
            </ul>
          </div>
        );
      });
    }
    return <h1>No compatible expressions</h1>;
  };

  const renderOther = () => {
    if (expressionAssets.length) {
      return expressionAssets.map((asset) => {
        const isActive = avatar[asset.slot]?.uuid === asset.uuid;

        return (
          <div key={asset.uuid} onClick={() => handleExpressionSelected(asset)}>
            <ul className={`img-display-list`}>
              <li
                className={`${
                  isActive && "img-dis-active"
                } slot-${currentSlot}`}
              >
                <LazyLoadImage
                  effect="blur"
                  src={asset.thumbnail}
                  alt={asset.display_name}
                ></LazyLoadImage>
              </li>
            </ul>
          </div>
        );
      });
    }

    if (colorAssets.length) {
      return colorAssets.map((asset) => {
        const isActive = avatar[asset.slot]?.uuid === asset.uuid;

        return (
          <div key={asset.uuid} onClick={() => handleColorSelected(asset)}>
            <ul className={`img-display-list`}>
              <li
                className={`${
                  isActive && "img-dis-active"
                } slot-${currentSlot}`}
              >
                <LazyLoadImage
                  effect="blur"
                  src={asset.thumbnail}
                  alt={asset.display_name}
                ></LazyLoadImage>
              </li>
            </ul>
          </div>
        );
      });
    }

    if (eyesForEliteApe.length) {
      return eyesForEliteApe.map((asset) => {
        const isActive = avatar.body?.uuid === asset.uuid;

        return (
          <div key={asset.uuid} onClick={() => handleColorSelected(asset)}>
            <ul className={`img-display-list`}>
              <li
                className={`${
                  isActive && "img-dis-active"
                } slot-${currentSlot}`}
              >
                <LazyLoadImage
                  effect="blur"
                  src={asset.thumbnail}
                  alt={asset.display_name}
                ></LazyLoadImage>
              </li>
            </ul>
          </div>
        );
      });
    }

    return <h1>No compatible expressions</h1>;
  };

  const renderPopupExpressions = () => {
    return null;
    /*
    return (
      <div>
        <div>
          <ul className="img-display-list"></ul>
        </div>

        <div className="upr-part">
          <p className="text-white">Select Expression</p>
          <button onClick={() => handleSlotSelected("body")}>
            Back
          </button>
        </div>

        <div className="btm-part">
          <ul
            className={`img-display-list ${
              currentSlot === "body" ? "zoom-face" : ""
            } `}
            id="cs-scrolling"
          >
            {bodyStyleAssets.map((i) => {
                const assetExists = Object.values(avatar)
                  .filter(asset => asset.uuid === i.uuid).length > 0;

                return (
                  <li
                    key={i.uuid}
                    onClick={() => handleBodyStyleSelected(i)}
                    className={assetExists ? "img-dis-active" : ""}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src={i.thumbnail}
                    ></LazyLoadImage>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
*/
  };

  const renderPopupColors = () => {
    return null;
    /*
    return (
      <div>
        <div>
          <ul className="img-display-list"></ul>
        </div>

        <div className="upr-part">
          <p className="text-white">select color</p>
          <button onClick={() => handleResetSlot()}>
            Back
          </button>
        </div>

        <div className={"btm-part"}>
          <ul
            className={`img-display-list ${
              currentSlot === "body" ? "zoom-face" : ""
            } `}
            id="cs-scrolling"
          >
            {colorAssets.map((i) => {
              const assetExists = Object.values(avatar)
                .filter(asset => asset.uuid === i.uuid ).length > 0;

              return (
                <li
                  key={i.uuid}
                  onClick={() => handleColorSelected(i)}
                  className={assetExists ? "img-dis-active" : ""}
                >
                  <LazyLoadImage
                    effect="blur"
                    src={i.thumbnail}
                  ></LazyLoadImage>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
*/
  };

  const showColorPopup = false;
  const showExpressions = false;
  return (
    <div className="App">
      <div className="main-content">
        <div className="AvaHead">
          <h1>
            <img alt="" className="av-log" src={Av_logo} />
          </h1>
          <button className="walletButton" onClick={() => handleConnect(true)}>
            {account
              ? "Connected: " +
                account.substring(0, 6) +
                "..." +
                account.substring(38)
              : "Connect Wallet"}
          </button>
        </div>

        <div className="middle-content-part ">
          <div className="left-text">
            <div
              className={`middleFilterOption ${
                hasLightingModal ? "show" : "hide"
              }`}
            >
              <ul>
                {lightVariations.map((asset) => {
                  return (
                    <li
                      key={asset.uuid}
                      className="light-variation"
                      onClick={() => handleSelectLightSetup(asset)}
                    >
                      <LazyLoadImage
                        // effect="blur"
                        key={asset.uuid}
                        style={{ zIndex: asset.z_index }}
                        src={asset.medium}
                      ></LazyLoadImage>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="middleFilterIcon">
              <img
                alt=""
                src={LightBulb}
                onClick={() => handleToggleLightSetup()}
              />
            </div>
            {Object.values(avatar).map((asset) => {
              return (
                <LazyLoadImage
                  key={asset.uuid}
                  className="boxItem avatar-frame"
                  style={{ zIndex: asset.z_index }}
                  src={asset.medium}
                ></LazyLoadImage>
              );
            })}
          </div>

          <div className="right-text">
            <div className="icon-bar">
              <Slider {...settings}>
                {slotsNew.map((i) => {
                  if (currentSlot === "helmet") {
                    return (
                      <>
                        <SlotsIcons
                          key={i.id}
                          slot={i}
                          selectedSlot={currentSlot}
                          onClick={handleSlotSelected}
                          disable={true}
                        />
                        <p className="tooltip-text">{i.name}</p>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <SlotsIcons
                          key={i.id}
                          slot={i}
                          selectedSlot={currentSlot}
                          onClick={handleSlotSelected}
                        />

                        <p className="tooltip-text">{i.name}</p>
                      </>
                    );
                  }
                })}
              </Slider>
            </div>
            {/* <div className="submenu-bar">submenu</div> */}

            <div className="select-bar">
              <select
                value={collectionFilter}
                onChange={(e) => handleCollectionFilter(e)}
                style={{ backgroundImage: `url(${Arrow})` }}
              >
                {collections.map((val) => (
                  <option key={val.slug} value={val.slug}>
                    {val.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="main-head">
              <div className="select-asset">
                <h4>Select Traits</h4>
                {/* {showHelmetBackBtn && (
                  <button onClick={() => setShowColorPopup(true)}>Back</button>
                )} */}
                <div className="no-symb">
                  <LazyLoadImage
                    src={Ban}
                    effect="blur"
                    onClick={handleResetSlot}
                  ></LazyLoadImage>
                </div>
              </div>
              <div className="display-bar scroll" id="cs-scrolling">
                {currentSlot === "body" ? renderBody() : renderOther()}
              </div>
              <div className={`sh-pop ${showColorPopup ? "show-popup" : ""}`}>
                {/* BODY ONLY */}
                {showExpressions
                  ? renderPopupExpressions()
                  : renderPopupColors()}
              </div>

              {/* show pop-up end */}
            </div>
          </div>
        </div>

        <div className="mintButtonOuter">
          <button className="MintButton" onClick={handleMint}>
            Mint
          </button>
        </div>
      </div>
    </div>
  );
};

export default Minter;
