
import BASE          from "../assets/images/new-icons/body.png";
import BASE_SELECTED from "../assets/images/new-icons/body.png";

import BOTTOM_CLOTHING          from "../assets/images/new-icons/top.png";
import BOTTOM_CLOTHING_SELECTED from "../assets/images/new-icons/top.png";

import BOTTOM_ACCESSORY          from "../assets/images/new-icons/held.png";
import BOTTOM_ACCESSORY_SELECTED from "../assets/images/new-icons/held.png";

import HELMETS          from "../assets/images/new-icons/helmet.png";
import HELMETS_SELECTED from "../assets/images/new-icons/helmet.png";

import TOP_ACCESSORY          from "../assets/images/new-icons/extra.png";
import TOP_ACCESSORY_SELECTED from "../assets/images/new-icons/extra.png";

import HOOD_HAT_HAIR          from "../assets/images/new-icons/hat.png";
import HOOD_HAT_HAIR_SELECTED from "../assets/images/new-icons/hat.png";

import MASKS          from "../assets/images/new-icons/mask.png";
import MASKS_SELECTED from "../assets/images/new-icons/mask.png";

import HOOD          from "../assets/images/new-icons/hood.png";
import HOOD_SELECTED from "../assets/images/new-icons/hood.png";

//import TOP_CLOTHING from "../assets/images/new-icons/neck.png";
//import TOP_CLOTHING_SELECTED from "../assets/images/new-icons/neck.png";

import BACKGROUND          from "../assets/images/new-icons/background.png";
import BACKGROUND_SELECTED from "../assets/images/new-icons/background.png";

/*
import BG_Atlantis from "../assets/images/AM_BGs/BG_Atlantis.png";
import BG_Blue from "../assets/images/AM_BGs/BG_Blue.png";
import BG_Yellow from "../assets/images/AM_BGs/BG_Yellow.png";
import BG_Turquoise from "../assets/images/AM_BGs/BG_Turquoise.png";
import BG_sunset from "../assets/images/AM_BGs/BG_sunset.png";
import BG_Sunrise from "../assets/images/AM_BGs/BG_Sunrise.png";
import BG_Red from "../assets/images/AM_BGs/BG_Red.png";
import BG_Purple from "../assets/images/AM_BGs/BG_Purple.png";
import BG_Pink from "../assets/images/AM_BGs/BG_Pink.png";
import BG_Pastel from "../assets/images/AM_BGs/BG_Pastel.png";
import BG_Orange from "../assets/images/AM_BGs/BG_Orange.png";
import BG_neon from "../assets/images/AM_BGs/BG_neon.png";
import BG_Light_Blue from "../assets/images/AM_BGs/BG_Light_Blue.png";
import BG_Green from "../assets/images/AM_BGs/BG_Green.png";
import BG_Dark from "../assets/images/AM_BGs/BG_Dark.png";
*/
import Newhat from "../assets/images/new-icons/hat.png";
import Newmask from "../assets/images/new-icons/mask.png";
import DisableHelemt from "../assets/images/new-icons/helmet.png";

export const slotsNew = [
  {
    id: 1,
    title: "body",
    name: "Body",
    img: BASE,
    selectedImage: BASE_SELECTED,
  },
  {
    id: 2,
    title: "b-clothing",
    name: "Bottom clothing",
    img: BOTTOM_CLOTHING,
    selectedImage: BOTTOM_CLOTHING_SELECTED,
  },
  {
    id: 3,
    title: "b-item",
    name: "Bottom accessory",
    img: BOTTOM_ACCESSORY,
    selectedImage: BOTTOM_ACCESSORY_SELECTED,
  },
  {
    id: 4,
    title: "helmet",
    name: "Helmet",
    img: HELMETS,
    selectedImage: HELMETS_SELECTED,
    disable: DisableHelemt,
  },
  {
    id: 5,
    title: "hair",
    name: "Head Items",
    img: HOOD_HAT_HAIR,
    selectedImage: HOOD_HAT_HAIR_SELECTED,
    disable: Newhat,
  },
  {
    id: 6,
    title: "mask",
    name: "Mask",
    img: MASKS,
    selectedImage: MASKS_SELECTED,
    disable: Newmask,
  },
  {
    id: 7,
    title: "hood",
    name: "Hood",
    img: HOOD,
    selectedImage: HOOD_SELECTED,
  },
  {
    id: 8,
    title: "t-item",
    name: "Top accessory",
    img: TOP_ACCESSORY,
    selectedImage: TOP_ACCESSORY_SELECTED,
  },
  {
    id: 9,
    title: "background",
    name: "Background",
    img: BACKGROUND,
    selectedImage: BACKGROUND_SELECTED,
  },
];

/*
export const backGrounds = [
  {
    uuid: 1,
    title: "BG_Atlantis",
    thumbnail: BG_Atlantis,
    image: BG_Atlantis,
  },
  {
    uuid: 2,
    title: "BG_Blue",
    thumbnail: BG_Blue,
    image: BG_Blue,
  },
  {
    uuid: 3,
    title: "BG_Yellow",
    thumbnail: BG_Yellow,
    image: BG_Yellow,
  },
  {
    uuid: 4,
    title: "BG_Turquoise",
    thumbnail: BG_Turquoise,
    image: BG_Turquoise,
  },
  {
    uuid: 5,
    title: "BG_sunset",
    thumbnail: BG_sunset,
    image: BG_sunset,
  },

  {
    uuid: 6,
    title: "BG_Sunrise",
    thumbnail: BG_Sunrise,
    image: BG_Sunrise,
  },
  {
    uuid: 7,
    title: "BG_Red",
    thumbnail: BG_Red,
    image: BG_Red,
  },
  {
    uuid: 8,
    title: "BG_Purple",
    thumbnail: BG_Purple,
    image: BG_Purple,
  },
  {
    uuid: 9,
    title: "BG_Pink",
    thumbnail: BG_Pink,
    image: BG_Pink,
  },
  {
    uuid: 10,
    title: "BG_Pastel",
    thumbnail: BG_Pastel,
    image: BG_Pastel,
  },
  {
    uuid: 11,
    title: "BG_Orange",
    thumbnail: BG_Orange,
    image: BG_Orange,
  },
  {
    uuid: 12,
    title: "BG_neon",
    thumbnail: BG_neon,
    image: BG_neon,
  },
  {
    uuid: 13,
    title: "BG_Light_Blue",
    thumbnail: BG_Light_Blue,
    image: BG_Light_Blue,
  },
  {
    uuid: 14,
    title: "BG_Green",
    thumbnail: BG_Green,
    image: BG_Green,
  },

  {
    uuid: 15,
    title: "BG_Dark",
    thumbnail: BG_Dark,
    image: BG_Dark,
  },
];
*/
